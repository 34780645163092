.app {
  min-width: 360px;
}

.content {
  min-height: calc(100vh - 60px);
}

.footer {
  height: 60px;
}
