#app-description-header-col {
  text-align: center;
}

#app-description-header-info {
  margin-top: 5%;
}

.borderless-text-area {
  border: none;
  background-color: transparent;
  resize: none;
  outline: none;
}

.borderless-input {
  border: none;
  outline: none;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

@media (min-width: 768px) {
  #app-description-header-col {
    display: flex;
    align-items: center;
    text-align: justify;
  }

  #app-description-header-info {
    display: inline-block;
    margin-top: 0%;
    margin-left: 5%;
  }
}
