/* .notification-container {
  z-index: 1060;
  min-width: 250px;
  max-width: 350px;
} */

.notification-container {
  width: 300px;
  z-index: 1060;
}

@media (min-width: 768px) {
  .notification-container {
    width: 500px;
    z-index: 1060;
  }
}
